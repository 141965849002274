<template>
	<div>
		<b-card title="Payment time period">
			<validation-observer v-if="!loading">
				<b-row>
					<b-col md="6" cols="12">
						<b-form-group label="Forms" label-for="form">
							<validation-provider #default="{ errors }" name="Forms">
								<v-select
									id="form"
									v-model="formData.formId"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="forms"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col md="6" cols="12">
						<b-form-group label="Projects" label-for="project">
							<validation-provider #default="{ errors }" name="Projects">
								<v-select
									id="project"
									v-model="formData.projectId"
									label="name"
									:filterable="false"
									:searchable="false"
									:options="projects"
									:reduce="(o) => o.id"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col class="d-flex justify-content-end align-items-center mt-1">
						<p v-show="!validRange" class="text-danger mb-0 mr-2">Sorry, Invalid date range!</p>
						<loading-btn variant="primary" :loading="loading">
							Get Workers
						</loading-btn>
					</b-col>
				</b-row>
			</validation-observer>
			<div v-else class="">
				<div class="text-center text-primary mt-3 mb-2">
					<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
					<span class="d-block mt-1">Loading...</span>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard, BRow, BCol, BSpinner, BFormGroup } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import LoadingBtn from '@/components/LoadingBtn.vue';
import api from '@/utils/api';
import vSelect from 'vue-select';

export default {
	name: 'ReportsBuilder',

	components: {
		BCard,
		BRow,
		BCol,
		BSpinner,
		BFormGroup,
		ValidationProvider,
		ValidationObserver,

		LoadingBtn,
		vSelect
	},

	data: () => ({
		forms: [],
		projects: [],
		formData: {
			formId: '',
			projectId: ''
		},

		loading: false
	}),

	created() {
		this.loading = true;

		try {
			this.loadForms();
			this.loadProjects();
			this.loading = false;
		} catch (error) {
			console.log(error);
			this.loading = false;
		}
	},

	methods: {
		async loadForms() {
			const { data } = await api.call({ path: '/forms', method: 'GET', noLoading: true });
			this.forms = data.data;
		},

		async loadProjects() {
			const { data } = await api.call({ path: '/projects', method: 'GET', noLoading: true });
			console.log(data);
			this.projects = data.data;
		}
	}
};
</script>

<style lang="scss" scoped></style>
